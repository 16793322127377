<template>
  <div>
    <p class="mb-1 gray-lighten--text">
      <strong>Adresse chantier:</strong> {{ currentProject ? currentProject.address_construction : '' }}
    </p>
    <p class="mb-0">
      <strong>Ville :</strong>
      {{ currentProject ? currentProject.city : '' }}
    </p>
    <p class="mb-0">
      <strong>Code postal :</strong>
      {{currentProject ? currentProject.postal_code : '' }}
    </p>
    <p class="mb-0">
      <strong>Téléphone :</strong>
      {{ userContact.phone }}
    </p>
    <p class="mb-0">
      <strong>E-mail : </strong>
      <a :href="`mailto:${userContact.email}`">{{
        userContact.email
      }}</a>
    </p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "ContactDetailsView",
  computed: {
    ...mapGetters({
      projectAddress: "project/getCurrentProjectAddress",
      currentProject: "project/getCurrentProject",
      userContact: "project/getCurrentUserAddress"
    })
  }
};
</script>

<style></style>
